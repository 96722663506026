@font-face {
font-family: '__fontMono_5e811e';
src: url(/_next/static/media/e9b7d49ac47d2793-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__fontMono_Fallback_5e811e';src: local("Arial");ascent-override: 55.41%;descent-override: 19.56%;line-gap-override: 0.00%;size-adjust: 146.10%
}.__className_5e811e {font-family: '__fontMono_5e811e', '__fontMono_Fallback_5e811e'
}.__variable_5e811e {--fonts-mono: '__fontMono_5e811e', '__fontMono_Fallback_5e811e'
}

@font-face {
font-family: '__fontSans_c75cce';
src: url(/_next/static/media/79030718ef2d8011-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__fontSans_Fallback_c75cce';src: local("Arial");ascent-override: 76.08%;descent-override: 26.85%;line-gap-override: 0.00%;size-adjust: 106.41%
}.__className_c75cce {font-family: '__fontSans_c75cce', '__fontSans_Fallback_c75cce'
}.__variable_c75cce {--fonts-sans: '__fontSans_c75cce', '__fontSans_Fallback_c75cce'
}

